import * as msal from "@azure/msal-browser";

interface IAccountState {
    msalAccount: msal.AccountInfo;
}

const initialState: IAccountState = {
    msalAccount: null,
};

export { IAccountState, initialState };
