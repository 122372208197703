import { Options, Vue } from "vue-class-component";
import { WatchStopHandle } from "vue";

import EstimatifLinesTableBaseProps from "./EstimatifLinesTableBaseProps";

import { PlusOutlined } from "@ant-design/icons-vue";
import notification from "ant-design-vue/lib/notification";

import draggable from "vuedraggable";
import CreateOrUpdateEstimatifLine from "./CreateOrUpdateEstimatifLine.vue";
import ModifyCategorieModal from "@/components/Shared/Estimatifs/ModifyCategorie/ModifyCategorieModal.vue";
import MoveLineModal from "@/components/Shared/Estimatifs/Move/MoveLineModal.vue";
import NumberFormat from "@/components/Shared/NumberFormat/NumberFormat.vue";
import PriceEvolutionModal from "@/components/Shared/Estimatifs/PriceEvolution/PriceEvolutionModal.vue";
import SetTvaModal from "@/components/Shared/Estimatifs/SetTva/SetTvaModal.vue";

import { estimatifLinesReferenceHttp } from "@/httpClients";

import * as models from "@/models";

@Options({
    name: "estimatif-lines-table",
    components: {
        CreateOrUpdateEstimatifLine,
        draggable,
        "number-format": NumberFormat,
        "plus-outlined": PlusOutlined,
        "modify-categorie-modal": ModifyCategorieModal,
        "move-line-modal": MoveLineModal,
        "set-tva-modal": SetTvaModal,
        "price-evolution-modal": PriceEvolutionModal,
    },
    emits: [ "updateCategorie" ],
})
export default class EstimatifLinesTableBase extends Vue.with(EstimatifLinesTableBaseProps) {

    public unwatchCategorieIdSelected: WatchStopHandle = null;

    public lockDragging = false;

    public estimatifLines: models.IEstimatifLineRowDefinition[] = [];

    public estimatifLineDrawerIsVisible = false;

    public isLoadingEstimatifLines = false;

    public estimatifLineToUpdate: models.IEstimatifBase;

    public modalSetTvaParameters: models.ISetTvaParameters = {
        visible: false,
    }

    public modalModifyCategorieParameters: models.IModifyCategorieParameters = {
        visible: false,
        estimatifId: null,
        categories: null,
    }

    public modalMoveLineParameters: models.IMoveLineParameters = {
        visible: false,
        estimatifId: null,
        reference: null,
    }

    public modalPriceEvolutionData: models.IPriceEvolution = {
        visible: false,
    };

    public numeroEstimatifToCopy = "";

    public get percentageValueOptions(): Intl.NumberFormatOptions {
        return {
            style: "percent",
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
        };
    }

    public get tvas(): models.ITva[] {
        const data = this.$store.state["references"].tvas as models.ITva[] ?? [];
        return data.sort((tva1, tva2) => tva1.taux - tva2.taux);
    }

    public get unites(): models.IUnite[] {
        const data = this.$store.state["references"].unites as models.IUnite[] ?? [];
        return data.sort((unite1, unite2) => unite1.libelle.localeCompare(unite2.libelle));
    }

    public created(): void {
        this.unwatchCategorieIdSelected = this.$watch("categoriesSelected.categorieIdSelected", () => this.onCategoriesSelectionChanged());
    }

    public unmounted(): void {
        if (this.unwatchCategorieIdSelected) {
            this.unwatchCategorieIdSelected();
        }
    }

    public closeEstimatifLineDrawer(): void {
        this.estimatifLineDrawerIsVisible = false;
        this.estimatifLineToUpdate = null;
    }

    public async addToLines(estimatifLine: models.IEstimatifBase): Promise<void> {
        this.estimatifLines.push({
            key: estimatifLine.estimatifId.toString(),
            ...estimatifLine,
            estEnCoursSuppression: false,
            commentaire: null,
            estimatifClientId: null,
            marge: null,
            minute: null,
            quantite: null,
            isMinute: false,
            rowClass: this.specificRowClass(estimatifLine),
        });

        await this.updateOrdre();

        const element = document.getElementById(estimatifLine.estimatifId.toString());
        element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }

    public specificRowClass(record: models.IEstimatifBase): any {
        const rowClass: any = {};

        if (record.isPrincipalTitle) {
            rowClass.principalTitleRow = true;
            rowClass.bold = true;
        }

        if (record.isSecondaryTitle) {
            rowClass.underline = true;
        }

        if (record.isNonChiffrable) {
            rowClass.bold = record.isNonChiffrableBold;
            rowClass.underline = record.isNonChiffrableUnderline;
        }

        return rowClass;
    }

    public createOrUpdateEstimatifLineDrawer(estimatifLine: models.IEstimatifBase): void {
        this.estimatifLineToUpdate = estimatifLine;
        this.estimatifLineDrawerIsVisible = true;
    }

    public async supprimer(record: models.IEstimatifLineRowDefinition): Promise<void> {
        record.estEnCoursSuppression = true;
        const isSuccess = await estimatifLinesReferenceHttp.deleteEstimatifLine(record.estimatifId);

        const estimatif = this.estimatifLines.find(estimatifLine => estimatifLine.estimatifId === record.estimatifId) ?? null;
        if (isSuccess && estimatif) {
            const index = this.estimatifLines.indexOf(estimatif, 0);
            if (index > -1) {
                this.estimatifLines.splice(index, 1);
            }

            this.updateOrdre();
        }

        record.estEnCoursSuppression = false;
    }

    public async updateOrdre(): Promise<void> {
        this.estimatifLines.forEach((estimatifLine, index) => estimatifLine.ordre = index + 1);

        await estimatifLinesReferenceHttp.updateOrdres(this.estimatifLines.map(estimatifLine => ({
            estimatifId: estimatifLine.estimatifId,
            ordre: estimatifLine.ordre,
        })));
    }

    public updateEstimatifLine(element: models.IEstimatifLineRowDefinition): void {
        const estimatifLine = this.estimatifLines.find(el => el.estimatifId === element.estimatifId);

        if (estimatifLine) {
            estimatifLinesReferenceHttp.updateEstimatifLine(element.estimatifId, element);
        }
    }

    public async updateEstimatifLineEdition(estimatifLineUpdated: models.IEstimatifBase): Promise<void> {
        const estimatifLine = this.estimatifLines.find(el => el.estimatifId === estimatifLineUpdated.estimatifId);
        estimatifLine.designation = estimatifLineUpdated.designation;
        estimatifLine.isNonChiffrable = estimatifLineUpdated.isNonChiffrable;
        estimatifLine.isNonChiffrableBold = estimatifLineUpdated.isNonChiffrableBold;
        estimatifLine.isNonChiffrableUnderline = estimatifLineUpdated.isNonChiffrableUnderline;
        estimatifLine.isPrincipalTitle = estimatifLineUpdated.isPrincipalTitle;
        estimatifLine.isSecondaryTitle = estimatifLineUpdated.isSecondaryTitle;
        estimatifLine.prixUnitaire = estimatifLineUpdated.prixUnitaire;
        estimatifLine.tvaId = estimatifLineUpdated.tvaId;
        estimatifLine.uniteId = estimatifLineUpdated.uniteId;
    }

    // ------------ UPDATE TVA ------------------------
    public openModalTva(): void {
        this.modalSetTvaParameters.visible = true;
    }

    public async setTvas(tvaId: number): Promise<void> {
        if (tvaId) {
            const estimatifIds: number[] = [];
            this.estimatifLines
                .filter(estimatifLine => !estimatifLine.isPrincipalTitle && !estimatifLine.isSecondaryTitle && !estimatifLine.isNonChiffrable)
                .forEach(estimatifLine => {
                    estimatifLine.tvaId = tvaId;
                    estimatifIds.push(estimatifLine.estimatifId);
                });

            const isSuccess = await estimatifLinesReferenceHttp.updateTvas({
                estimatifIds,
                tvaId,
            });

            if (isSuccess) {
                notification.success({
                    message: "Mise à jour des TVA",
                    description: "La mise à jour des TVA du lot a été effectué avec succès.",
                });

                this.modalSetTvaParameters.visible = false;
            }
        }
    }
    // ------------ UPDATE TVA ------------------------

    // ------------ MODIFY CATEGORIE ------------------------
    public openModifyCategorie(estimatifId: number): void {
        this.modalModifyCategorieParameters.estimatifId = estimatifId;
        this.modalModifyCategorieParameters.categories = {
            firstCategorieId: this.categoriesSelected.firstCategorieId,
            secondCategorieId: this.categoriesSelected.secondCategorieId,
            thirdCategorieId: this.categoriesSelected.thirdCategorieId,
        };
        this.modalModifyCategorieParameters.visible = true;
    }

    public async updateEstimatifLineCategorie(categorieId: number): Promise<void> {
        // Save change category and update order with the new max order + 1 of the new category
        const success = await estimatifLinesReferenceHttp.updateCategorie({
            estimatifId: this.modalModifyCategorieParameters.estimatifId,
            categorieId,
        });

        if (success) {
            const estimatif = this.estimatifLines.find(estimatifLine => estimatifLine.estimatifId === this.modalModifyCategorieParameters.estimatifId) ?? null;
            if (estimatif) {
                // Delete line from the array
                const index = this.estimatifLines.indexOf(estimatif, 0);
                if (index > -1) {
                    this.estimatifLines.splice(index, 1);
                }

                // Update current category order
                await this.updateOrdre();

                this.$emit("updateCategorie", this.modalModifyCategorieParameters.categories);

                this.modalModifyCategorieParameters.visible = false;
                this.modalModifyCategorieParameters.estimatifId = null;
            }
        }
        else {
            this.modalModifyCategorieParameters.visible = false;
            this.modalModifyCategorieParameters.estimatifId = null;
            this.modalModifyCategorieParameters.categories = null;
        }
    }
    // ------------ MODIFY CATEGORIE ------------------------

    // ------------ PRICE EVOLUTION ------------------------
    public openModalAdjustPrice(): void {
        this.modalPriceEvolutionData.visible = true;
    }

    public async updatePrices(coef: number): Promise<void> {
        const pricesToUpdate: models.IUpdatePrixUnitaire[] = [];

        this.estimatifLines.forEach(estimatifLine => {
            if (estimatifLine.prixUnitaire) {
                const value = estimatifLine.prixUnitaire * coef;
                estimatifLine.prixUnitaire = parseFloat(value.toFixed(2));

                pricesToUpdate.push({
                    estimatifId: estimatifLine.estimatifId,
                    prixUnitaire: estimatifLine.prixUnitaire,
                });
            }
        });

        const isSuccess = await estimatifLinesReferenceHttp.updatePrices(pricesToUpdate);

        if (isSuccess) {
            notification.success({
                message: "Mise à jour des prix",
                description: "La mise à jour des prix du lot a été effectué avec succès.",
            });
        }
    }
    // ------------ PRICE EVOLUTION ------------------------

    // ------------ UPDATE ORDRE ------------------------
    public openModalMoveLine(estimatifLine: models.IEstimatifLineRowDefinition): void {
        this.modalMoveLineParameters.visible = true;
        this.modalMoveLineParameters.estimatifId = estimatifLine.estimatifId;
        this.modalMoveLineParameters.reference = estimatifLine.ordre;
    }

    public async moveLine(reference: number): Promise<void> {
        const estimatif = this.estimatifLines.find(estimatifLine => estimatifLine.estimatifId === this.modalMoveLineParameters.estimatifId) ?? null;
        if (estimatif) {
            const index = this.estimatifLines.indexOf(estimatif, 0);
            if (index > -1) {
                // Delete at current index
                this.estimatifLines.splice(index, 1);
                // Insert at new index
                this.estimatifLines.splice(reference - 1, 0, estimatif);
            }

            await this.updateOrdre();

            this.modalMoveLineParameters.visible = false;
            this.modalMoveLineParameters.estimatifId = null;
            this.modalMoveLineParameters.reference = null;

            const element = document.getElementById(estimatif.estimatifId.toString());
            element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        }
        else {
            this.modalMoveLineParameters.estimatifId = null;
            this.modalMoveLineParameters.reference = null;
        }
    }
    // ------------ UPDATE ORDRE ------------------------

    public focus(antdvInputElement: any): void {
        // Lock dragging
        this.lockDragging = true;
        // Select input content
        antdvInputElement.target.select();
    }

    public blur(): void {
        // Unlock dragging
        this.lockDragging = false;
    }

    private async onCategoriesSelectionChanged(): Promise<void> {
        if (this.categoriesSelected.categorieIdSelected) {
            this.isLoadingEstimatifLines = true;
            this.estimatifLines = [];

            const lines = await estimatifLinesReferenceHttp.getLignesEstimatif(this.categoriesSelected.categorieIdSelected);

            this.estimatifLines =
                lines.sort((el1, el2) => el1.ordre - el2.ordre).map(estimatifLine => ({
                    key: estimatifLine.estimatifId.toString(),
                    ...estimatifLine,
                    estEnCoursSuppression: false,
                    commentaire: null,
                    estimatifClientId: null,
                    marge: null,
                    minute: null,
                    quantite: null,
                    isMinute: false,
                    rowClass: this.specificRowClass(estimatifLine),
                })) ?? [];

            this.isLoadingEstimatifLines = false;
        }
        else {
            this.estimatifLines = [];
        }
    }
}
