import { notification } from "ant-design-vue";

import BaseHttp from "./BaseHttp";

import * as models from "@/models/api";

export default class CategoriesHttp extends BaseHttp {
    public constructor() {
        super("Categories");
    }

    public async categorieIdsWithEstimatifLines(): Promise<number []> {
        return await this.get("/ids-with-estimatif-lines", "Une erreur s'est produite lors de la récupération des catégories utilisées par les lignes des estimatifs");
    }

    public async createCategorie(categorie: models.ICategorie): Promise<{ id: number; state: boolean }> {
        return await this.create("", categorie, "Une erreur s'est produite lors de la création de la catégorie");
    }

    public async deleteCategorie(categorieId: number): Promise<boolean> {
        return await this.delete(categorieId, "Une erreur s'est produite lors de la suppression de la catégorie");
    }

    public async updateCategorie(categorieId: number, categorie: models.ICategorie): Promise<boolean> {
        return await this.update("", categorieId, categorie, "Une erreur s'est produite de la mise à jour de la catégorie");
    }

    public async updateReferences(references: models.IUpdateReference[]): Promise<boolean> {
        try {
            const response = await this.apiClient.patch("update-references", references);

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Error",
                description: "Une erreur s'est produite lors de la sauvegarde de la référence",
            });
        }

        return false;
    }
}

const categoriesHttp = new CategoriesHttp();
export { CategoriesHttp, categoriesHttp };
