import Vuex from "vuex";
import { IAccountState } from "./account/state";
import { IReferencesState } from "./references/state";

import { account } from "./account";
import { references } from "./references";

interface IState {
    account: IAccountState;
    references: IReferencesState;
}

const store = new Vuex.Store<IState>({
    modules: {
        account,
        references,
    },
});

export { IState, store };
