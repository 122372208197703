import { Options, Vue } from "vue-class-component";
import { WatchStopHandle } from "vue";

import notification from "ant-design-vue/lib/notification";

import EstimatifLinesTableBase from "@/components/references/estimatifLinesBase/EstimatifLinesTableBase.vue";
import SelectCategorie from "@/components/Shared/Estimatifs/Categories/SelectCategorie.vue";
import PriceEvolutionModal from "@/components/Shared/Estimatifs/PriceEvolution/PriceEvolutionModal.vue";

import { estimatifLinesReferenceHttp } from "@/httpClients";

import * as models from "@/models";

@Options({
    name: "estimatif-line-base-view",
    components: {
        "estimatif-lines-table-base": EstimatifLinesTableBase,
        "select-categorie": SelectCategorie,
        "price-evolution-modal": PriceEvolutionModal,
    },
})
export default class EstimatifLineBaseView extends Vue {
    public categoriesSelected: models.ICategoriesSelection = {
        firstCategorieId: null,
        secondCategorieId: null,
        thirdCategorieId: null,
    }

    public categoriesSelectedBeforeUpdatePrice: models.ICategoriesSelection = null;

    public modalPriceEvolutionData: models.IPriceEvolution = {
        visible: false,
    };

    public openModalAdjustPrice(): void {
        this.categoriesSelectedBeforeUpdatePrice = { ...this.categoriesSelected };
        this.categoriesSelected.firstCategorieId = null;
        this.categoriesSelected.secondCategorieId = null;
        this.categoriesSelected.thirdCategorieId = null;
        this.modalPriceEvolutionData.visible = true;
    }

    public updateCategorie(categoriesUpdated: models.ICategoriesSelection): void {
        this.categoriesSelected.firstCategorieId = categoriesUpdated.firstCategorieId;
        this.categoriesSelected.secondCategorieId = categoriesUpdated.secondCategorieId;
        this.categoriesSelected.thirdCategorieId = categoriesUpdated.thirdCategorieId;
    }

    public async updatePrices(coef: number): Promise<void> {
        const isSuccess = await estimatifLinesReferenceHttp.updateGlobalPrices(coef);

        if (isSuccess) {
            notification.success({
                message: "Mise à jour des prix",
                description: "La mise à jour des prix de l'estimatif de référence a été effectué avec succès.",
            });
        }

        this.categoriesSelected.firstCategorieId = this.categoriesSelectedBeforeUpdatePrice.firstCategorieId;
        this.categoriesSelected.secondCategorieId = this.categoriesSelectedBeforeUpdatePrice.secondCategorieId;
        this.categoriesSelected.thirdCategorieId = this.categoriesSelectedBeforeUpdatePrice.thirdCategorieId;
        this.categoriesSelectedBeforeUpdatePrice = null;
    }
}
