import { Options, Vue } from "vue-class-component";
import { WatchStopHandle } from "vue";

import * as models from "@/models";

import draggable from "vuedraggable";
import { categoriesHttp } from "@/httpClients/CategoriesHttp";

@Options({
    name: "referential-categories-view",
    components: {
        draggable,
    },
})
export default class ReferentialCategoriesView extends Vue {
    public categorieIdsWithEstimatifLines: number[] = [];

    public firstCategorieId: number = null;

    public secondCategorieId: number = null;

    public categories: models.ICategorie[] = [];

    public modalCreateOrUpdateCategorieIsVisible = false;

    public createOrUpdateCategorie: models.ICategorie = null;

    public readonly validateMessages = {
        required: "Le champ est requis !",
    };

    public unwatchRoute: WatchStopHandle = null;

    public unwatchFirstCategorie: WatchStopHandle = null;

    public unwatchSecondCategorie: WatchStopHandle = null;

    public get categoriesHierarchie(): Record<number, models.ICategorieHierarchie> {
        return this.$store.state["references"]?.categoriesHierarchie as Record<number, models.ICategorieHierarchie>
            ?? {};
    }

    public get firstCategories(): models.ICategorie[] {
        return Object.values(this.categoriesHierarchie)
            .map(ch => ch.categorie)
            .sort((ch1, ch2) => ch1.reference - ch2.reference);
    }

    public get secondCategories(): models.ICategorie[] {
        return this.firstCategorieId
            ? Object.values(this.categoriesHierarchie[this.firstCategorieId].categoriesHierarchie ?? {})
                .map(ch => ch.categorie)
                .sort((ch1, ch2) => ch1.reference - ch2.reference)
            : null;
    }

    public get thirdCategories(): models.ICategorie[] {
        return this.secondCategorieId
            ? Object.values(
                this.categoriesHierarchie[this.firstCategorieId]
                    .categoriesHierarchie[this.secondCategorieId]
                    .categoriesHierarchie ?? {})
                .map(ch => ch.categorie)
                .sort((ch1, ch2) => ch1.reference - ch2.reference)
            : [];
    }

    public get modalCreateOrUpdateCategorieTitle(): string {
        return this.createOrUpdateCategorie?.categorieId === 0
            ? "Création d'une catégorie"
            : "Mise à jour d'une catégorie";
    }

    public created(): void {
        this.firstCategorieId = this.$route.query.firstCategorieId ? parseInt(this.$route.query.firstCategorieId as string, 10) : null;
        this.secondCategorieId = this.$route.query.secondCategorieId ? parseInt(this.$route.query.secondCategorieId as string, 10) : null;

        this.unwatchRoute = this.$watch("$route", () => this.onRouteChange());
        this.unwatchFirstCategorie = this.$watch("firstCategorieId", () => this.onCategoriesSelectionChanged());
        this.unwatchSecondCategorie = this.$watch("secondCategorieId", () => this.onCategoriesSelectionChanged());
    }

    public async mounted(): Promise<void> {
        this.categorieIdsWithEstimatifLines = await categoriesHttp.categorieIdsWithEstimatifLines();
        this.onRouteChange();
    }

    public unmounted(): void {
        if (this.unwatchFirstCategorie) {
            this.unwatchFirstCategorie();
        }

        if (this.unwatchSecondCategorie) {
            this.unwatchSecondCategorie();
        }
    }

    public async onRouteChange(): Promise<void> {
        if (this.secondCategorieId) {
            this.categories = Object.values(this.thirdCategories);
        }
        else if (this.firstCategorieId) {
            this.categories = Object.values(this.secondCategories);
        }
        else {
            this.categories = Object.values(this.firstCategories);
        }
    }

    public hasNotSubCategoriesOrEstimatifLines(categorie: models.ICategorie): boolean {
        let hasNotSubCategories = false;

        if (this.secondCategorieId) {
            hasNotSubCategories =
                Object.keys(
                    this.categoriesHierarchie[this.firstCategorieId]
                        .categoriesHierarchie[this.secondCategorieId]
                        .categoriesHierarchie[categorie.categorieId].categoriesHierarchie ?? {}).length === 0;
        }
        else if (this.firstCategorieId) {
            hasNotSubCategories =
                Object.keys(
                    this.categoriesHierarchie[this.firstCategorieId]
                        .categoriesHierarchie[categorie.categorieId].categoriesHierarchie ?? {}).length === 0;
        }
        else {
            hasNotSubCategories = Object.keys(this.categoriesHierarchie[categorie.categorieId].categoriesHierarchie ?? {}).length === 0;
        }

        return hasNotSubCategories && this.categorieIdsWithEstimatifLines.find(cat => cat === categorie.categorieId) === undefined;
    }

    public closeModalCreateOrUpdateCategorie(): void {
        this.modalCreateOrUpdateCategorieIsVisible = false;
    }

    public createCategorie(): void {
        this.createOrUpdateCategorie = {
            categorieId: 0,
            reference: this.categories.length + 1,
            libelle: null,
            level: 1,
        };

        if (this.secondCategorieId) {
            this.createOrUpdateCategorie.parentId = this.secondCategorieId;
            this.createOrUpdateCategorie.level = 3;
        }
        else if (this.firstCategorieId) {
            this.createOrUpdateCategorie.parentId = this.firstCategorieId;
            this.createOrUpdateCategorie.level = 2;
        }

        this.modalCreateOrUpdateCategorieIsVisible = true;
    }

    public modifyCategorie(categorie: models.ICategorie): void {
        this.createOrUpdateCategorie = {
            categorieId: categorie.categorieId,
            reference: categorie.reference,
            libelle: categorie.libelle,
            level: categorie.level,
            parentId: categorie.parentId,
        };

        this.modalCreateOrUpdateCategorieIsVisible = true;
    }

    public onCategoriesSelectionChanged(): void {
        if (this.secondCategorieId) {
            this.$router.push({
                name: "referentiel-categories",
                query: {
                    firstCategorieId: this.firstCategorieId,
                    secondCategorieId: this.secondCategorieId,
                },
            });
        }
        else if (this.firstCategorieId) {
            this.$router.push({
                name: "referentiel-categories",
                query: {
                    firstCategorieId: this.firstCategorieId,
                },
            });
        }
        else {
            this.$router.push({
                name: "referentiel-categories",
            });
        }
    }

    public onFirstCategorieSelectionChange(): void {
        this.secondCategorieId = null;
    }

    public async submitCreateOrUpdateCategorieHandler(): Promise<void> {
        if (this.createOrUpdateCategorie.categorieId === 0) {
            const result: ICreateCategorieResult = await this.$store.dispatch("references/createCategorie", this.createOrUpdateCategorie);

            if (result.state) {
                await this.$store.dispatch("references/fetchCategoriesHierarchie", this.createOrUpdateCategorie);
            }
        }
        else {
            const isSuccess = await this.$store.dispatch("references/updateCategorie", this.createOrUpdateCategorie);

            if (isSuccess) {
                await this.$store.dispatch("references/fetchCategoriesHierarchie", this.createOrUpdateCategorie);
            }
        }
    }

    public async deleteCategorie(categorie: models.ICategorie): Promise<void> {
        const isSuccess: boolean = await this.$store.dispatch("references/deleteCategorie", categorie.categorieId);

        if (isSuccess) {
            const index = this.categories.indexOf(categorie, 0);
            if (index > -1) {
                this.categories.splice(index, 1);
            }

            if (this.categories.length > 0) {
                this.updateReferences();
            }
        }
    }

    public updateReferences(): void {
        this.categories.forEach((categorie, index) => categorie.reference = index + 1);

        this.$store.dispatch("references/updateCategoriesReferences", this.categories);
    }
}

interface ICreateCategorieResult {
    id: number;
    state: boolean;
}
