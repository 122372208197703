import { Options, Vue } from "vue-class-component";

import SetTvaModalProps from "./SetTvaModalProps";

import NumberFormat from "../../NumberFormat/NumberFormat.vue";

import { ITva } from "@/models";

@Options({
    name: "set-marge-modal",
    components: {
        "number-format": NumberFormat,
    },
    emits: ["update:parameters", "setTva"],
})
export default class SetMargeModal extends Vue.with(SetTvaModalProps) {
    public tvaId: number = null;

    public get disabled(): boolean {
        return !this.tvaId;
    }

    public get percentageValueOptions(): Intl.NumberFormatOptions {
        return {
            style: "percent",
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
        };
    }

    public get tvas(): ITva[] {
        const data = this.$store.state["references"].tvas as ITva[] ?? [];
        return data.sort((tva1, tva2) => tva1.taux - tva2.taux);
    }

    public close(): void {
        this.parameters.visible = false;
        this.tvaId = null;

        this.$emit("update:parameters", this.parameters);
    }

    public setTva(): void {
        if (this.tvaId) {
            this.$emit("setTva", this.tvaId);
        }
    }
}
