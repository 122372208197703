import { notification } from "ant-design-vue";
import BaseHttp from "./BaseHttp";

import * as models from "@/models/api";

export default class EstimatifHttp extends BaseHttp {
    public constructor() {
        super("Estimatif");
    }

    public async copyCategorie(
        estimatifClientIdFrom: number,
        estimatifClientIdTo: number,
        categorieIdToCopy: number): Promise<boolean> {

        if (estimatifClientIdFrom == null || estimatifClientIdFrom === 0
            || estimatifClientIdTo == null || estimatifClientIdTo === 0
            || categorieIdToCopy == null || categorieIdToCopy === 0) {
            notification.error({
                message: "Erreur",
                description: "Un des identifiants permettant la copie n'a pas été fournit",
            });
        }

        try {
            const response = await this.apiClient.post(`copy-categorie/${estimatifClientIdFrom}/${estimatifClientIdTo}/${categorieIdToCopy}`);

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Erreur",
                description: "Une erreur s'est produite lors de la sauvegarde de la catégorie",
            });
        }

        return false;
    }

    public async createEstimatifLine(estimatif: models.IEstimatif): Promise<{ id: number; state: boolean }> {
        return await this.create("", estimatif, "Une erreur s'est produite dans la création de l'estimatif");
    }

    public async getLignesEstimatif(estimatifClientId: number, categorieId: number): Promise<models.IEstimatif[]> {
        const data = await this.get<models.IEstimatif[]>(
            `/${estimatifClientId}/${categorieId}`,
            "Une erreur s'est produite lors de la récupération des lignes de l'estimatif de la catégorie");
        return data ?? [];
    }

    public async updateCategorie(categorie: models.IUpdateCategorie): Promise<boolean> {
        try {
            const response = await this.apiClient.patch("update-categorie", categorie);

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Error",
                description: "Une erreur s'est produite lors de la sauvegarde de la catégorie",
            });
        }

        return false;
    }

    public async updateMarges(marges: models.IUpdateMarges): Promise<boolean> {
        try {
            const response = await this.apiClient.patch("update-marges", marges);

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Error",
                description: "Une erreur s'est produite lors de la sauvegarde des marges",
            });
        }

        return false;
    }

    public async updateOrdres(ordres: models.IUpdateOrdre[]): Promise<boolean> {
        try {
            const response = await this.apiClient.patch("update-ordres", ordres);

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Error",
                description: "Une erreur s'est produite lors de la sauvegarde de l'ordre",
            });
        }

        return false;
    }

    public async updatePrices(prices: models.IUpdatePrixUnitaire[]): Promise<boolean> {
        try {
            const response = await this.apiClient.patch("update-prices", prices);

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Error",
                description: "Une erreur s'est produite lors de la sauvegarde des prix",
            });
        }

        return false;
    }

    public async updateTvas(tvas: models.IUpdateTvas): Promise<boolean> {
        try {
            const response = await this.apiClient.patch("update-tvas", tvas);

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Error",
                description: "Une erreur s'est produite lors de la sauvegarde des TVA",
            });
        }

        return false;
    }

    public async deleteEstimatifLine(estimatifId: number): Promise<boolean> {
        return await this.delete(estimatifId, "Une erreur s'est produite lors de la suppression de la ligne de l'estimatif");
    }

    public async updateEstimatifLine(estimatifId: number, estimatif: models.IEstimatif): Promise<boolean> {
        return await this.update("", estimatifId, estimatif, "Une erreur s'est produite lors de la mise à jour de la ligne de l'estimatif");
    }
}

const estimatifHttp = new EstimatifHttp();
export { EstimatifHttp, estimatifHttp };
