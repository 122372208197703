import { Options, Vue } from "vue-class-component";
import { WatchStopHandle } from "vue";

import SelectCategorieProps from "./SelectCategorieProps";

import * as models from "@/models";

@Options({
    name: "select-categorie",
})
export default class SelectCategorie extends Vue.with(SelectCategorieProps) {

    public unwatchCategorieIdSelected: WatchStopHandle = null;

    public get categoriesHierarchie(): Record<number, models.ICategorieHierarchie> {
        return this.$store.state["references"]?.categoriesHierarchie as Record<number, models.ICategorieHierarchie>
            ?? {};
    }

    public get firstCategories(): models.ICategorie[] {
        return Object.values(this.categoriesHierarchie)
            .map(ch => ch.categorie)
            .sort((ch1, ch2) => ch1.reference - ch2.reference);
    }

    public get secondCategories(): models.ICategorie[] {
        return this.categoriesSelected.firstCategorieId
            ? Object.values(this.categoriesHierarchie[this.categoriesSelected.firstCategorieId].categoriesHierarchie ?? {})
                .map(ch => ch.categorie)
                .sort((ch1, ch2) => ch1.reference - ch2.reference)
            : null;
    }

    public get thirdCategories(): models.ICategorie[] {
        return this.categoriesSelected.secondCategorieId
            ? Object.values(
                this.categoriesHierarchie[this.categoriesSelected.firstCategorieId]
                    .categoriesHierarchie[this.categoriesSelected.secondCategorieId]
                    .categoriesHierarchie ?? {})
                .map(ch => ch.categorie)
                .sort((ch1, ch2) => ch1.reference - ch2.reference)
            : [];
    }

    public get categorieIdSelected(): number {
        let categorieId = null;

        if (this.categoriesSelected.thirdCategorieId) {
            categorieId = this.categoriesSelected.thirdCategorieId;
        }
        else if (this.categoriesSelected.secondCategorieId) {
            categorieId = this.categoriesSelected.secondCategorieId;
        }
        else {
            categorieId = null;
        }

        return categorieId;
    }

    public created(): void {
        this.unwatchCategorieIdSelected = this.$watch("categorieIdSelected", () => this.onCategoriesSelectionReady());
    }

    public unmounted(): void {
        if (this.unwatchCategorieIdSelected) {
            this.unwatchCategorieIdSelected();
        }
    }

    public onFirstCategorieSelectionChange(): void {
        this.categoriesSelected.secondCategorieId = null;
        this.categoriesSelected.thirdCategorieId = null;
    }

    public onSecondCategorieSelectionChange(): void {
        this.categoriesSelected.thirdCategorieId = null;
    }

    private onCategoriesSelectionReady(): void {
        this.categoriesSelected.categorieIdSelected = this.categorieIdSelected;
    }
}
