import { Options, Vue } from "vue-class-component";
import { WatchStopHandle } from "vue";

import CreateOrUpdateEstimatifLineProps from "./CreateOrUpdateEstimatifLineProps";

import NumberFormat from "@/components/Shared/NumberFormat/NumberFormat.vue";

import * as models from "@/models";
import { estimatifLinesReferenceHttp } from "@/httpClients";

@Options({
    name: "create-or-update-estimatif-line",
    components: {
        "number-format": NumberFormat,
    },
    emits: ["addToLines", "updateEstimatifLine", "close"],
})
export default class CreateOrUpdateEstimatifLine extends Vue.with(CreateOrUpdateEstimatifLineProps) {

    public internalEstimatifLine: models.IEstimatifBase = {
        categorieId: this.categoriesSelected.categorieIdSelected,
        designation: null,
        estimatifId: 0,
        isNonChiffrable: false,
        isNonChiffrableBold: false,
        isNonChiffrableUnderline: false,
        isPrincipalTitle: false,
        isSecondaryTitle: false,
        ordre: 0,
        prixUnitaire: null,
        tvaId: this.tvas[this.tvas.length - 1].tvaId,
        uniteId: this.unites[0].uniteId,
    };

    public unwatchIsPrincipalTitle: WatchStopHandle = null;

    public unwatchIsSecondaryTitle: WatchStopHandle = null;

    public unwatchIsNonChiffrable: WatchStopHandle = null;

    public readonly validateMessages = {
        required: "Le champ est requis !",
    };

    public get percentageValueOptions(): Intl.NumberFormatOptions {
        return {
            style: "percent",
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
        };
    }

    public get isTitleSelected(): boolean {
        return this.internalEstimatifLine.isPrincipalTitle
            || this.internalEstimatifLine.isSecondaryTitle;
    }

    public get isNonChiffrableSelected(): boolean {
        return this.isTitleSelected || this.internalEstimatifLine.isNonChiffrable;
    }

    public created(): void {
        if (this.estimatifLine) {
            this.internalEstimatifLine = { ...this.estimatifLine };
        }

        this.unwatchIsPrincipalTitle = this.$watch("internalEstimatifLine.isPrincipalTitle", () => this.onIsPrincipalTitleChanged());
        this.unwatchIsSecondaryTitle = this.$watch("internalEstimatifLine.isSecondaryTitle", () => this.onIsSecondaryTitleChange());
        this.unwatchIsNonChiffrable = this.$watch("internalEstimatifLine.isNonChiffrable", () => this.onIsNonChiffrableChange());
    }

    public unmounted(): void {
        if (this.unwatchIsPrincipalTitle) {
            this.unwatchIsPrincipalTitle();
        }

        if (this.unwatchIsSecondaryTitle) {
            this.unwatchIsSecondaryTitle();
        }

        if (this.unwatchIsNonChiffrable) {
            this.unwatchIsNonChiffrable();
        }
    }

    public close(): void {
        this.$emit("close");
    }

    public async submitCreateOrUpdateEstimatifLineHandler(): Promise<void> {
        if (this.estimatifLine) {
            const success = await estimatifLinesReferenceHttp.updateEstimatifLine(this.internalEstimatifLine.estimatifId, this.internalEstimatifLine);

            if (success) {
                this.$emit("updateEstimatifLine", this.internalEstimatifLine);
                this.close();
            }
        }
        else {
            const result = await estimatifLinesReferenceHttp.createEstimatifLine(this.internalEstimatifLine);

            if (result.state) {
                this.internalEstimatifLine.estimatifId = result.id;
                this.$emit("addToLines", this.internalEstimatifLine);
                this.close();
            }
        }
    }

    private onIsNonChiffrableChange(): void {
        if (this.internalEstimatifLine.isNonChiffrable) {
            this.internalEstimatifLine.isPrincipalTitle = false;

            this.resetIfNonChiffrableActivated();
        }
        else {
            this.internalEstimatifLine.isNonChiffrableBold = false;
            this.internalEstimatifLine.isNonChiffrableUnderline = false;

            this.setDefaultIfNecessary();
        }
    }

    private onIsPrincipalTitleChanged(): void {
        if (this.internalEstimatifLine.isPrincipalTitle) {
            this.internalEstimatifLine.isSecondaryTitle = false;

            this.resetIfTitleActivated();
            this.resetIfNonChiffrableActivated();
        }
        else {
            this.setDefaultIfNecessary();
        }
    }

    private onIsSecondaryTitleChange(): void {
        if (this.internalEstimatifLine.isSecondaryTitle) {
            this.internalEstimatifLine.isPrincipalTitle = false;

            this.resetIfTitleActivated();
            this.resetIfNonChiffrableActivated();
        }
        else {
            this.setDefaultIfNecessary();
        }
    }

    private resetIfTitleActivated(): void {
        this.internalEstimatifLine.isNonChiffrable = false;
    }

    private resetIfNonChiffrableActivated(): void {
        this.internalEstimatifLine.prixUnitaire = null;
        this.internalEstimatifLine.tvaId = null;
        this.internalEstimatifLine.uniteId = null;
    }

    private setDefaultIfNecessary(): void {
        if (!this.internalEstimatifLine.isPrincipalTitle
            && !this.internalEstimatifLine.isSecondaryTitle
            && !this.internalEstimatifLine.isNonChiffrable) {
            this.internalEstimatifLine.tvaId = this.tvas[0].tvaId;
            this.internalEstimatifLine.uniteId = this.unites[0].uniteId;
        }
    }
}
