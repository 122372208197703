import { MutationTree } from "vuex";
import { IReferencesState } from "./state";
import * as mutationsTypes from "./mutations-types";
import { ICategorieHierarchie, ICivilite, IProjet, ITva, IUnite } from "@/models/api";

export const mutations: MutationTree<IReferencesState> = {
    [mutationsTypes.FETCH_CATEGORIES_HIERARCHIE]: (state) => {
        state.categoriesHierarchieLoaded = false;
    },

    [mutationsTypes.SET_CATEGORIES_HIERARCHIE]: (state, payload: Record<number, ICategorieHierarchie>) => {
        state.categoriesHierarchie = {...payload};
        state.categoriesHierarchieLoaded = true;
    },

    [mutationsTypes.FETCH_CIVILITES]: (state) => {
        state.civilitesLoaded = false;
    },

    [mutationsTypes.SET_CIVILITES]: (state, payload: ICivilite[]) => {
        state.civilites = [...payload];
        state.civilitesLoaded = true;
    },

    [mutationsTypes.FETCH_PROJETS]: (state) => {
        state.projetsLoaded = false;
    },

    [mutationsTypes.SET_PROJETS]: (state, payload: IProjet[]) => {
        state.projets = [...payload];
        state.projetsLoaded = true;
    },

    [mutationsTypes.FETCH_TVAS]: (state) => {
        state.tvasLoaded = false;
    },

    [mutationsTypes.SET_TVAS]: (state, payload: ITva[]) => {
        state.tvas = [...payload];
        state.tvasLoaded = true;
    },

    [mutationsTypes.FETCH_UNITES]: (state) => {
        state.unitesLoaded = false;
    },

    [mutationsTypes.SET_UNITES]: (state, payload: IUnite[]) => {
        state.unites = [...payload];
        state.unitesLoaded = true;
    },
};
