import { Options, mixins } from "vue-class-component";
import { WatchStopHandle } from "vue";
import CommonMixin from "../CommonMixin";

import { clientsHttp } from "@/httpClients/ClientsHttp";
import * as models from "@/models/api";
import dayjs from "dayjs";

import { UserAddOutlined } from "@ant-design/icons-vue";

@Options({
    components: {
        "user-add-outlined": UserAddOutlined,
    },
})
export default class ClientList extends mixins(CommonMixin) {
    public clients: IRowDefinition[] = [];

    public isLoading = true;

    public isLoadingTable = true;

    public searchText = "";

    public createOrUpdateClientIsVisible = false;

    public createOrUpdateClient: models.IClient = null;

    public includeClientWithArchivedProjects = false;

    public unwatchIncludeArchivedProjects: WatchStopHandle = null;

    public readonly validateMessages = {
        required: "${label} est requis !",
    };

    public get civilites(): models.ICivilite[] {
        const civilites = this.$store.getters["references/civilites"] as models.ICivilite[] ?? [];
        return civilites.sort((civ1, civ2) => civ1.code.localeCompare(civ2.code));
    }

    public get clientsColumns(): IColumnDefinition[] {
        return [
            {
                key: "dateCreation",
                dataIndex: "dateCreation",
                title: "Date de création",
                width: "12%",
            },
            {
                key: "projets",
                dataIndex: "projets",
                title: "Projets",
                width: "30%",
            },
            {
                key: "client",
                dataIndex: "client",
                title: "Nom / Société",
                width: "30%",
            },
            {
                key: "action",
                dataIndex: "action",
                title: "",
            },
        ];
    }

    public get clientsDatasource(): IRowDefinition[] {
        let clientsFiltered: IRowDefinition[];

        if (this.searchText.length > 0) {
            clientsFiltered = this.clients
                .filter(client =>
                    (client.nom.toLowerCase().includes(this.searchText.toLowerCase())
                    || client.projets.some(projet => projet.toLowerCase().startsWith(this.searchText))));
        }
        else {
            clientsFiltered = this.clients;
        }

        return clientsFiltered.sort((clt1, clt2) => clt2.dateCreation.toDate().getTime() - clt1.dateCreation.toDate().getTime());
    }

    public get modalCreateOrUpdateTitle(): string {
        return this.createOrUpdateClient?.clientId === 0
            ? "Créer un nouveau client"
            : "Modification du client";
    }

    public async mounted(): Promise<void> {
        await Promise.all([
            this.chargerClients(),
        ]);

        this.isLoading = false;
    }

    public created(): void {
        this.unwatchIncludeArchivedProjects = this.$watch("includeClientWithArchivedProjects", () => this.chargerClients());
    }

    public unmounted(): void {
        if (this.unwatchIncludeArchivedProjects) {
            this.unwatchIncludeArchivedProjects();
        }
    }

    public async chargerClients(): Promise<void> {
        this.isLoadingTable = true;
        const clts = await clientsHttp.getClients(this.includeClientWithArchivedProjects);

        this.clients = clts.map(client => ({
            key: client.clientId.toString(),
            clientId: client.clientId,
            civiliteId: client.civiliteId,
            nom: client.nom,
            prenom: client.prenom,
            adresse: client.adresse,
            adresseComplement: client.adresseComplement,
            codePostal: client.codePostal,
            ville: client.ville,
            telephone: client.telephone,
            portable: client.portable,
            email: client.email,
            projets: client.projets,
            dateCreation: dayjs(client.dateCreation),
            estEnCoursArchivage: false,
            estEnCoursSuppression: false,
        }));

        this.isLoadingTable = false;
    }

    public navigateToEstimatifClient(client: models.IClient): void {
        this.$router.push({ name: "estimatifClient", params: { id: client.clientId }});
    }

    public obtenirDateAffichage(record: IRowDefinition): string {
        return record.dateCreation.format("DD/MM/YYYY");
    }

    public obtenirCiviliteAffichage(record: IRowDefinition): string {
        return this.getCiviliteRef(record.civiliteId).code;
    }

    public obtenirContactAffichage(record: IRowDefinition): string {
        let affichageTelephone = "";

        if (record.telephone) {
            affichageTelephone = record.telephone;
        }

        if (record.portable) {
            affichageTelephone += (affichageTelephone === "" ? record.portable : ` - ${record.portable}`);
        }

        return affichageTelephone;
    }

    public obtenirNomAffichage(record: IRowDefinition): string {
        return record.prenom ? `${record.nom} ${record.prenom}` : record.nom;
    }

    public showCreateOrUpdateClient(client?: models.IClient): void {
        if (client) {
            this.createOrUpdateClient = {
                clientId: client.clientId,
                civiliteId: client.civiliteId,
                nom: client.nom,
                prenom: client.prenom,
                adresse: client.adresse,
                adresseComplement: client.adresseComplement,
                codePostal: client.codePostal,
                ville: client.ville,
                telephone: client.telephone,
                portable: client.portable,
                email: client.email,
                projets: client.projets,
                dateCreation: dayjs(client.dateCreation),
            };
        }
        else {
            this.createOrUpdateClient = {
                clientId: 0,
                civiliteId: this.civilites[0].civiliteId,
                nom: null,
                prenom: null,
                adresse: null,
                adresseComplement: null,
                codePostal: null,
                ville: null,
                telephone: null,
                portable: null,
                email: null,
                projets: [],
                dateCreation: dayjs(),
            };
        }

        this.createOrUpdateClientIsVisible = true;
    }

    public async submitCreateOrUpdateClientHandler(): Promise<void> {
        if (this.createOrUpdateClient.clientId === 0) {
            const result = await clientsHttp.createClient(this.createOrUpdateClient);

            if (result.state) {
                this.createOrUpdateClient.clientId = result.id;
                this.clients.push({
                    key: result.id.toString(),
                    ...this.createOrUpdateClient,
                    estEnCoursSuppression: false,
                });

                this.createOrUpdateClientIsVisible = false;
            }
        }
        else {
            const state = await clientsHttp.updateClient(this.createOrUpdateClient.clientId, this.createOrUpdateClient);

            if (state) {
                const client = this.clients.find(clt => clt.clientId === this.createOrUpdateClient.clientId);

                client.civiliteId = this.createOrUpdateClient.civiliteId;
                client.nom = this.createOrUpdateClient.nom;
                client.prenom = this.createOrUpdateClient.prenom;
                client.adresse = this.createOrUpdateClient.adresse;
                client.adresseComplement = this.createOrUpdateClient.adresseComplement;
                client.codePostal = this.createOrUpdateClient.codePostal;
                client.ville = this.createOrUpdateClient.ville;
                client.telephone = this.createOrUpdateClient.telephone;
                client.portable = this.createOrUpdateClient.portable;
                client.email = this.createOrUpdateClient.email;
            }

            this.createOrUpdateClientIsVisible = false;
        }
    }

    public async supprimer(record: IRowDefinition): Promise<void> {
        record.estEnCoursSuppression = true;
        const estSupprime = await clientsHttp.deleteClient(record.clientId);

        const client = this.obtenirClient(record.clientId);
        if (estSupprime && client) {
            const index = this.clients.indexOf(client, 0);
            if (index > -1) {
                this.clients.splice(index, 1);
            }
        }
    }

    private obtenirClient(clientId: number): IRowDefinition | null {
        return this.clients.find(client => client.clientId === clientId) ?? null;
    }
}

interface IColumnDefinition {
    key: string;
    dataIndex: string;
    title: string;
    width?: string | number;
}

interface IRowDefinition extends models.IClient {
    key: string;
    estEnCoursSuppression: boolean;
}
