import { Options, Vue } from "vue-class-component";

import MoveLineModalProps from "./MoveLineModalProps";

@Options({
    name: "set-marge-modal",
    emits: ["update:parameters", "moveLine"],
})
export default class SetMargeModal extends Vue.with(MoveLineModalProps) {
    public get disabled(): boolean {
        return !this.parameters.reference;
    }

    public close(): void {
        this.parameters.visible = false;
        this.parameters.estimatifId = null;
        this.parameters.reference = null;

        this.$emit("update:parameters", this.parameters);
    }

    public updateReference(): void {
        if (this.parameters.reference) {
            this.$emit("moveLine", this.parameters.reference);
        }
    }
}
