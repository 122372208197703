import { notification } from "ant-design-vue";
import BaseHttp from "./BaseHttp";

import * as models from "@/models/api";

export default class EstimatifLinesReferenceHttp extends BaseHttp {
    public constructor() {
        super("EstimatifLinesReference");
    }

    public async createEstimatifLine(estimatif: models.IEstimatifBase): Promise<{ id: number; state: boolean }> {
        return await this.create("", estimatif, "Une erreur s'est produite dans la création de l'estimatif");
    }

    public async getLignesEstimatif(categorieId: number): Promise<models.IEstimatifBase[]> {
        const data = await this.get<models.IEstimatifBase[]>(
            `/${categorieId}`,
            "Une erreur s'est produite lors de la récupération des lignes de l'estimatif de la catégorie");
        return data ?? [];
    }

    public async updateCategorie(categorie: models.IUpdateCategorieReference): Promise<boolean> {
        try {
            const response = await this.apiClient.patch("update-categorie", categorie);

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Error",
                description: "Une erreur s'est produite lors de la sauvegarde de la catégorie",
            });
        }

        return false;
    }

    public async updateGlobalPrices(coef: number): Promise<boolean> {
        try {
            const response = await this.apiClient.patch("update-global-prices", { coefficient: coef });

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Error",
                description: "Une erreur s'est produite lors de la sauvegarde des prix globaux",
            });
        }

        return false;
    }

    public async updateOrdres(ordres: models.IUpdateOrdre[]): Promise<boolean> {
        try {
            const response = await this.apiClient.patch("update-ordres", ordres);

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Error",
                description: "Une erreur s'est produite lors de la sauvegarde de l'ordre",
            });
        }

        return false;
    }

    public async updatePrices(prices: models.IUpdatePrixUnitaire[]): Promise<boolean> {
        try {
            const response = await this.apiClient.patch("update-prices", prices);

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Error",
                description: "Une erreur s'est produite lors de la sauvegarde des prix",
            });
        }

        return false;
    }

    public async updateTvas(tvas: models.IUpdateTvas): Promise<boolean> {
        try {
            const response = await this.apiClient.patch("update-tvas", tvas);

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Error",
                description: "Une erreur s'est produite lors de la sauvegarde des TVA",
            });
        }

        return false;
    }

    public async deleteEstimatifLine(estimatifId: number): Promise<boolean> {
        return await this.delete(estimatifId, "Une erreur s'est produite lors de la suppression de la ligne de l'estimatif");
    }

    public async updateEstimatifLine(estimatifId: number, estimatif: models.IEstimatifBase): Promise<boolean> {
        return await this.update("", estimatifId, estimatif, "Une erreur s'est produite lors de la mise à jour de la ligne de l'estimatif");
    }
}

const estimatifLinesReferenceHttp = new EstimatifLinesReferenceHttp();
export { EstimatifLinesReferenceHttp, estimatifLinesReferenceHttp };
