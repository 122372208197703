import { Module } from "vuex";
import { IAccountState, initialState } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const account: Module<IAccountState, any> = ({
    namespaced: true,
    state: () => initialState,
    getters,
    mutations,
    actions,
});

export { account };
