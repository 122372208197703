import * as models from "@/models/api";
import BaseHttp from "./BaseHttp";
import { IClient } from "@/models/api";

export default class ClientsHttp extends BaseHttp {
    public constructor() {
        super("Clients");
    }

    public async createClient(client: IClient): Promise<{ id: number; state: boolean }> {
        return await this.create("", client, "Une erreur s'est produite lors de la création du client");
    }

    public async getClient(clientId: number): Promise<models.IClient> {
        const data = await this.get<models.IClient>(`/${clientId}`, `Une erreur s'est produite lors de la récupération du client ${clientId}`);
        return data;
    }

    public async getClients(includeClientWithArchivedProjects: boolean): Promise<models.IClient[]> {
        const data = await this.get<models.IClient[]>(`?includeClientWithArchivedProjects=${includeClientWithArchivedProjects}`, "Une erreur s'est produite dans la récupération des clients");
        return data ?? [];
    }

    public async deleteClient(clientId: number): Promise<boolean> {
        return await this.delete(clientId, "Une erreur s'est produite lors de la suppression du client");
    }

    public async updateClient(clientId: number, client: IClient): Promise<boolean> {
        return await this.update("", clientId, client, "Une erreur s'est produite de la mise à jour du client");
    }
}

const clientsHttp = new ClientsHttp();
export { ClientsHttp, clientsHttp };
