import { Options, Vue } from "vue-class-component";
import ParametresEstimatifClientProps from "./ParametresEstimatifClientProps";

import { notification } from "ant-design-vue";
import { estimatifsClientHttp } from "@/httpClients";

import NumberFormat from "../Shared/NumberFormat/NumberFormat.vue";

import * as models from "@/models";

@Options({
    name: "parametres-estimatif-client",
    components: {
        "number-format": NumberFormat,
    },
})
export default class ParametresEstimatifClient extends Vue.with(ParametresEstimatifClientProps) {

    public internalEstimatifClient: models.IEstimatifClient = null;

    public marge: number = null;

    public tvaId: number = null;

    public get estArchive(): boolean {
        return this.internalEstimatifClient?.estArchive ?? true;
    }

    public get percentageValueOptions(): Intl.NumberFormatOptions {
        return {
            style: "percent",
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
        };
    }

    public get tvas(): models.ITva[] {
        const data = this.$store.state["references"].tvas as models.ITva[] ?? [];
        return data.sort((tva1, tva2) => tva1.taux - tva2.taux);
    }

    public created(): void {
        this.internalEstimatifClient = { ...this.estimatifClient };
    }

    public async resetMarge(): Promise<void> {
        const success = await estimatifsClientHttp.resetMarge(
            this.internalEstimatifClient.estimatifClientId);

        if (success) {
            notification.success({
                message: `Estimatif ${this.internalEstimatifClient.numero}-${this.internalEstimatifClient.version}`,
                description: "La margé a été réinitialisée avec succès.",
            });
        }
    }

    public async updateMarge(): Promise<void> {
        const success = await estimatifsClientHttp.updateMarge(
            this.internalEstimatifClient.estimatifClientId,
            this.marge);

        if (success) {
            this.marge = null;

            notification.success({
                message: `Estimatif ${this.internalEstimatifClient.numero}-${this.internalEstimatifClient.version}`,
                description: "La marge a été définie avec succès.",
            });
        }
    }

    public async updateTva(): Promise<void> {
        const success = await estimatifsClientHttp.updateTva(
            this.internalEstimatifClient.estimatifClientId,
            this.tvaId);

        if (success) {
            this.tvaId = null;

            notification.success({
                message: `Estimatif ${this.internalEstimatifClient.numero}-${this.internalEstimatifClient.version}`,
                description: "La TVA a été définie avec succès.",
            });
        }
    }

    public async updateParametrage(): Promise<void> {
        const success = await estimatifsClientHttp.updateParametrage(
            this.internalEstimatifClient.estimatifClientId,
            this.internalEstimatifClient);

        if (success) {
            notification.success({
                message: `Estimatif ${this.internalEstimatifClient.numero}-${this.internalEstimatifClient.version}`,
                description: "Le paramétrage a été mis à jour avec succès.",
            });

            this.$emit("updateEstimatifClient", this.internalEstimatifClient);
        }
    }
}
