import { ActionTree } from "vuex";
import { IReferencesState } from "./state";
import * as mutationsTypes from "./mutations-types";
import { referencesHttp } from "@/httpClients/ReferencesHttp";
import { categoriesHttp } from "@/httpClients/CategoriesHttp";
import { ICategorie, IUpdateReference } from "@/models";

export const actions: ActionTree<IReferencesState, any> = {
    fetchAll: async (context): Promise<void> => {
        await Promise.all([
            context.dispatch("fetchCategoriesHierarchie"),
            context.dispatch("fetchCivilites"),
            context.dispatch("fetchProjets"),
            context.dispatch("fetchTvas"),
            context.dispatch("fetchUnites"),
        ]);
    },

    fetchCategoriesHierarchie: async (context): Promise<void> => {
        context.commit(mutationsTypes.FETCH_CATEGORIES_HIERARCHIE);
        const categoriesHierarchie = await referencesHttp.obtenirCategoriesHierarchie();
        context.commit(mutationsTypes.SET_CATEGORIES_HIERARCHIE, categoriesHierarchie);
    },

    fetchCivilites: async (context): Promise<void> => {
        context.commit(mutationsTypes.FETCH_CIVILITES);
        const civilites = await referencesHttp.obtenirCivilites();
        context.commit(mutationsTypes.SET_CIVILITES, civilites);
    },

    fetchTvas: async (context): Promise<void> => {
        context.commit(mutationsTypes.FETCH_TVAS);
        const tvas = await referencesHttp.obtenirTvas();
        context.commit(mutationsTypes.SET_TVAS, tvas);
    },

    fetchProjets: async (context): Promise<void> => {
        context.commit(mutationsTypes.FETCH_PROJETS);
        const projets = await referencesHttp.obtenirProjets();
        context.commit(mutationsTypes.SET_PROJETS, projets);
    },

    fetchUnites: async (context): Promise<void> => {
        context.commit(mutationsTypes.FETCH_UNITES);
        const unites = await referencesHttp.obtenirUnites();
        context.commit(mutationsTypes.SET_UNITES, unites);
    },

    createCategorie: async (_, categorie: ICategorie): Promise<{ id: number; state: boolean }> =>
        await categoriesHttp.createCategorie(categorie),

    deleteCategorie: async (_, categorieId: number): Promise<boolean> =>
        await categoriesHttp.deleteCategorie(categorieId),

    updateCategorie: async (_, categorie: ICategorie): Promise<boolean> =>
        await categoriesHttp.updateCategorie(categorie.categorieId, categorie),

    updateCategoriesReferences: async (context, references: IUpdateReference[]): Promise<void> => {
        const isSuccess = await categoriesHttp.updateReferences(references);

        if (isSuccess) {
            context.dispatch("fetchCategoriesHierarchie");
        }
    },
};
