import { NavigationGuard } from "vue-router";
import { store } from "@/store";

import { msalInstance } from "@/services/MsalService";

export const authGuard: NavigationGuard = async (to, from, next) => {
    // Login with Azure AD
    const loggedIn = await msalInstance.login();
    if (!loggedIn) {
        return next("/unauthorized");
    }
    else {
        // Load user account
        await store.dispatch("account/loadAccount");
        if (!store.getters["account/isAuthenticated"]) {
            return next("/unauthorized");
        }
        else {
            return next();
        }
    }
};
