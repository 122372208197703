import { Options, Vue } from "vue-class-component";
import * as models from "@/models/api";

@Options({})
export default class CommonMixin extends Vue {
    public getCiviliteRef(civiliteId: number): models.ICivilite {
        return this.$store.getters["references/civilitesRecords"][civiliteId] ?? {
            civiliteId: 0,
            code: "-",
            libelle: "-",
        };
    }

    public getTvaRef(tvaId: number): models.ITva {
        return this.$store.getters["references/tvasRecords"][tvaId] ?? {
            tvaId: 0,
            taux: 0,
        };
    }

    public getUniteRef(uniteId: number): models.IUnite {
        return this.$store.getters["references/unitesRecords"][uniteId] ?? {
            uniteId: 0,
            code: "-",
            libelle: "-",
            isMinute: false,
        };
    }
}
