import { Options, Vue } from "vue-class-component";
import { WatchStopHandle } from "vue";

@Options({
    name: "app",
})
export default class App extends Vue {
    public unwatchIsAuthenticated: WatchStopHandle = null;

    public get referencesDataIsLoaded(): boolean {
        return this.$store.getters["references/referencesLoaded"] ?? false;
    }

    public get isAuthenticated(): boolean {
        return this.$store.getters["account/isAuthenticated"] ?? false;
    }

    public created(): void {
        this.unwatchIsAuthenticated = this.$watch("isAuthenticated", () => this.onUserAuthenticated());
    }

    public unmounted(): void {
        if (this.unwatchIsAuthenticated) {
            this.unwatchIsAuthenticated();
        }
    }

    public logout(): void {
        this.$store.dispatch("account/logout");
    }

    private async onUserAuthenticated(): Promise<void> {
        if (this.isAuthenticated) {
            // Fetch references data
            await this.$store.dispatch("references/fetchAll");
        }
    }
}
