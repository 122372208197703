
import { Options, Vue } from "vue-class-component";
import ClientList from "@/components/Clients/ClientList.vue";

@Options({
    components: {
        ClientList,
    },
})
export default class HomeView extends Vue {}
