import { Options, Vue } from "vue-class-component";

import PriceEvolutionModalProps from "./PriceEvolutionModalProps";

@Options({
    name: "price-evolution-modal",
    emits: ["update:priceEvolution", "updatePrices"],
})
export default class PriceEvolutionModal extends Vue.with(PriceEvolutionModalProps) {
    public type: AdjustPriceType = "increase";

    public percentage: number = null;

    public get disabledOk(): boolean {
        return !this.percentage || this.percentage === 0;
    }

    public close(): void {
        this.priceEvolution.visible = false;

        this.type = "increase";
        this.percentage = null;

        this.$emit("update:priceEvolution", this.priceEvolution);
    }

    public update(): void {
        let coef = this.percentage / 100;

        switch(this.type) {
            case "increase": {
                coef = 1 + coef;
                this.$emit("updatePrices", coef);
                break;
            }
            case "decrease": {
                coef = 1 - coef;
                this.$emit("updatePrices", coef);
                break;
            }
            default: {
                break;
            }
        }

        this.close();
    }
}

export type AdjustPriceType = "increase" | "decrease";
