import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { authGuard } from "./AuthGuard";

import HomeView from "../views/HomeView.vue";
import EstimatifLinesBaseView from "@/views/Referentials/EstimatifLineBase/EstimatifLinesBaseView.vue";
import EstimatifsClientView from "@/views/EstimatifsClient/EstimatifsClientView.vue";
import ReferentialCategoriesView from "@/views/Referentials/Categories/ReferentialCategoriesView.vue";

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/estimatif-client/:id",
        name: "estimatifClient",
        component: EstimatifsClientView,
    },
    {
        path: "/estimatif/:id",
        name: "estimatif",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/EstimatifLines/EstimatifView.vue"),
    },
    {
        path: "/referentiel-categories",
        name: "referentiel-categories",
        component: ReferentialCategoriesView,
    },
    {
        path: "/referentiel-estimatif",
        name: "referentiel-estimatif",
        component: EstimatifLinesBaseView,
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(authGuard);

export default router;
