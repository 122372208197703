import { notification } from "ant-design-vue";
import * as models from "@/models/api";
import { IEstimatifClient } from "@/models/api";
import BaseHttp from "./BaseHttp";
import { IAlerteSansMarge } from "@/models/api/IAlerteSansMarge";

export default class EstimatifsClientHttp extends BaseHttp {
    public constructor() {
        super("EstimatifsClient");
    }

    public async archiver(clientId: number): Promise<boolean> {
        try {
            const response = await this.apiClient.patch(`archiver/${clientId}`);

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Error",
                description: "Une erreur s'est produite lors de l'archivage du projet",
            });
        }

        return false;
    }

    public async createEstimatifClient(clientId: number, projetId: number): Promise<boolean> {
        try {
            const response = await this.apiClient.post(`/${clientId}/${projetId}`);

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Erreur",
                description: "Une erreur s'est produite lors de la création du projet",
            });

            return false;
        }
    }

    public async deleteEstimatifClient(estimatifClientId: number): Promise<boolean> {
        return await this.delete(estimatifClientId, "Une erreur s'est produite lors de la suppression de l'estimatif du client");
    }

    public async duplicate(estimatifClientId: number): Promise<boolean> {
        try {
            const response = await this.apiClient.post(`/duplicate/${estimatifClientId}`);

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Erreur",
                description: "Une erreur s'est produite lors de la duplication",
            });

            return false;
        }
    }

    public async getEstimatifsClientId(numero: number, version: number): Promise<number> {
        try {
            const response = await this.apiClient.get<number>(`/id/${numero}/${version}`);

            if (response.status === 200
                || response.status === 204) {
                return response.data;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Erreur",
                description: "Une erreur s'est produite dans la récupération de l'identifiant de l'estimatif client",
            });

            return null;
        }
    }

    public async getAlertesSansMarge(clientId: number): Promise<IAlerteSansMarge[]> {
        const data = await this.get<IAlerteSansMarge[]>(
            `/${clientId}/alertes-sans-marge`,
            "Une erreur s'est produite dans la récupération des alertes sans marge du client");
        return data ?? [];
    }

    public async getEstimatifsClient(clientId: number): Promise<models.IEstimatifClient[]> {
        const data = await this.get<models.IEstimatifClient[]>(
            `/${clientId}`,
            "Une erreur s'est produite dans la récupération des estimatifs du client");
        return data ?? [];
    }

    public async getEstimatifClientInfo(estimatifClientId: number): Promise<models.IEstimatifClientInfo> {
        const data = await this.get<models.IEstimatifClientInfo>(
            `/${estimatifClientId}/infos`,
            "Une erreur s'est produite lors de la récupération des infos de l'estimatif");
        return data;
    }

    public async resetMarge(estimatifClientId: number): Promise<boolean> {
        try {
            const response = await this.apiClient.patch(`marge/reset/${estimatifClientId}`);

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Error",
                description: "Une erreur s'est produite lors de la réinitialisation de la marge",
            });
        }

        return false;
    }

    public async updateMarge(estimatifClientId: number, marge: number): Promise<boolean> {
        try {
            const response = await this.apiClient.patch(`marge/${estimatifClientId}`, { marge });

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Error",
                description: "Une erreur s'est produite lors de la mise à jour de la marge",
            });
        }

        return false;
    }

    public async updateTva(estimatifClientId: number, tvaId: number): Promise<boolean> {
        try {
            const response = await this.apiClient.patch(`tva/${estimatifClientId}`, { tvaId });

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Error",
                description: "Une erreur s'est produite lors de la mise à jour de la TVA",
            });
        }

        return false;
    }

    public async updateParametrage(estimatifClientId: number, estimatifClient: IEstimatifClient): Promise<boolean> {
        return await this.update("parametrage", estimatifClientId, estimatifClient, "Une erreur s'est produite lors de la mise à jour du paramétrage");
    }
}

const estimatifsClientHttp = new EstimatifsClientHttp();
export { EstimatifsClientHttp, estimatifsClientHttp };
