import { prop } from "vue-class-component";

import { IMoveLineParameters } from "@/models";

export default class SetMargeModalProps {
    parameters: IMoveLineParameters = prop({
        required: true,
    });

    min: number = prop({
        required: true,
    });

    max: number = prop({
        required: true,
    });
}
