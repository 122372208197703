import { ActionTree } from "vuex";
import { LOAD_ACCOUNT, LOGOUT } from "./mutations-types";
import { IAccountState } from "./state";
import { msalInstance } from "@/services";

export const actions: ActionTree<IAccountState, any> = {
    loadAccount: (context): void => {
        const account = msalInstance.getActiveAccount();
        context.commit(LOAD_ACCOUNT, account);
    },

    logout: async (context): Promise<void> => {
        msalInstance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
        context.commit(LOGOUT);
    },
};
