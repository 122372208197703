import { Options, Vue } from "vue-class-component";

import fileDownload from "js-file-download";
import { parse } from "content-disposition-header";

import { clientsHttp, exportsHttp, estimatifsClientHttp } from "@/httpClients";

import dayjs from "dayjs";

import * as models from "@/models/api";

import { PlusOutlined } from "@ant-design/icons-vue";

import DeleteIcon from "@/components/Shared/Icons/DeleteIcon.vue";
import DuplicateIcon from "@/components/Shared/Icons/DuplicateIcon.vue";
import EditIcon from "@/components/Shared/Icons/EditIcon.vue";
import OfficeMaterialIcon from "@/components/Shared/Icons/OfficeMaterialIcon.vue";

import NumberFormat from "@/components/Shared/NumberFormat/NumberFormat.vue";
import ParametresEstimatifClient from "@/components/parametresEstimatif/ParametresEstimatifClient.vue";

@Options({
    components: {
        "delete-icon": DeleteIcon,
        "duplicate-icon": DuplicateIcon,
        "edit-icon": EditIcon,
        "number-format": NumberFormat,
        "office-material-icon": OfficeMaterialIcon,
        "parametres-estimatif-client": ParametresEstimatifClient,
        "plus-outlined": PlusOutlined,
    },
})
export default class EstimatifsClientView extends Vue {
    public client: models.IClient = null;

    public estimatifsClient: IEstimatifClientRowDefinition[] = [];

    public isClientLoading = true;

    public isEstimatifsLoading = true;

    public parametrageEstimatifIsVisible = false;

    public parametrageEstimatifDrawerTitle = "";

    public estimatifClient: models.IEstimatifClient = null;

    public projetIdSelected: number = null;

    public get clientId(): number {
        return parseInt(this.$route.params.id as string, 10);
    }

    // ------------- CARD -------------
    public get clientCardTitle(): string {
        return this.client
            ? `Projets du client ${this.civiliteDisplay} ${this.nomSocieteDisplay}`
            : "Client en cours de chargement";
    }

    public get civiliteDisplay(): string {
        return this.getCiviliteRef(this.client?.civiliteId).code;
    }

    public get contactDisplay(): string {
        let affichageTelephone = "";

        if (this.client) {
            if (this.client.telephone) {
                affichageTelephone = this.client.telephone;
            }

            if (this.client.portable) {
                affichageTelephone += (affichageTelephone === "" ? this.client.portable : ` - ${this.client.portable}`);
            }
        }

        return affichageTelephone;
    }

    public get nomSocieteDisplay(): string {
        return this.client
            ? this.client.prenom ? `${this.client.nom} ${this.client.prenom}` : this.client.nom
            : "";
    }
    // ------------- CARD -------------

    // ------------- TABLE -------------
    public get estimatisClientColumns(): IColumnDefinition[] {
        return [
            {
                key: "dateCreation",
                dataIndex: "dateCreation",
                title: "Date de création",
                width: "100px",
            },
            {
                key: "description",
                dataIndex: "description",
                title: "",
            },
            {
                key: "honoraire",
                dataIndex: "honoraire",
                title: "Honoraires",
                width: "110px",
            },
            {
                key: "commentaire",
                dataIndex: "commentaire",
                title: "Commentaire",
            },
            {
                key: "gestionEstimatif",
                dataIndex: "gestionEstimatif",
                title: "Gestion du projet",
                width: "215px",
            },
            {
                key: "editionEstimatif",
                dataIndex: "editionEstimatif",
                title: "Edition de l'estimatif du projet (Word)",
                width: "425px",
            },
        ];
    }
    // ------------- TABLE -------------

    public get projets(): models.IProjet[] {
        const prjs = this.$store.state["references"].projets as models.IProjet[] ?? [];
        return prjs.sort((prj1, prj2) => prj1.libelle.localeCompare(prj2.libelle));
    }

    public async mounted(): Promise<void> {
        if (this.clientId) {
            this.chargerClient().then(() => this.isClientLoading = false);
            this.chargerEstimatifsClient().then(() => this.isEstimatifsLoading = false);
            this.projetIdSelected = this.projets[0].projetId;
        }
    }

    public async chargerClient(): Promise<void> {
        this.isClientLoading = true;
        this.client = await clientsHttp.getClient(this.clientId);
    }

    public async chargerEstimatifsClient(): Promise<void> {
        this.isEstimatifsLoading = true;
        const estimatifs = await estimatifsClientHttp.getEstimatifsClient(this.clientId);
        const alertesSansMarge = await estimatifsClientHttp.getAlertesSansMarge(this.clientId);

        this.estimatifsClient = estimatifs.map(estimatifClient => ({
            key: estimatifClient.estimatifClientId.toString(),
            estimatifClientId: estimatifClient.estimatifClientId,
            dateCreation: dayjs(estimatifClient.dateCreation),
            dateModification: dayjs(estimatifClient.dateModification),
            numero: estimatifClient.numero,
            version: estimatifClient.version,
            numeroVersion: `${estimatifClient.numero}-${estimatifClient.version}`,
            honoraire: estimatifClient.honoraire,
            adresseConstruction: estimatifClient.adresseConstruction,
            adresseComplementConstruction: estimatifClient.adresseComplementConstruction,
            codePostalConstruction: estimatifClient.codePostalConstruction,
            villeConstruction: estimatifClient.villeConstruction,
            commentaire: estimatifClient.commentaire,
            projet: estimatifClient.projet,
            estArchive: estimatifClient.estArchive,
            montantHt: estimatifClient.montantHt,
            montantTtc: estimatifClient.montantTtc,
            exportState: {
                isDownloadingArtisan: false,
                isDownloadingArtisanAvecMetre: false,
                isDownloadingArtisanSansMetre: false,
                isDownloadingClient: false,
                isDownloadingClientAvecMetre: false,
                isDownloadingRealConcept: false,
                isDownloadingRealConceptAlerteSansMarge: false,
            },
            hasAlerteSansMarge: alertesSansMarge.find(asm => asm.estimatifClientId === estimatifClient.estimatifClientId) !== undefined,
        }));

        this.estimatifsClient = this.estimatifsClient.sort((ec1, ec2) => ec1.numeroVersion.localeCompare(ec2.numeroVersion));
    }

    public closeParametrageEstimatif(): void {
        this.parametrageEstimatifIsVisible = false;
        this.parametrageEstimatifDrawerTitle = "";
        this.estimatifClient = null;
    }

    public async archiverEstimatifsClient(): Promise<void> {
        const isSuccess = await estimatifsClientHttp.archiver(this.clientId);

        if (isSuccess) {
            this.chargerEstimatifsClient()
                .then(() => this.isEstimatifsLoading = false);
        }
    }

    public backToClients(): void {
        this.$router.push({ name: "home" });
    }

    public async createProjet(): Promise<void> {
        if (this.clientId > 0 && this.projetIdSelected > 0) {
            const isSuccess = await estimatifsClientHttp.createEstimatifClient(this.clientId, this.projetIdSelected);

            if (isSuccess) {
                this.projetIdSelected = this.projets[0].projetId;
                this.chargerEstimatifsClient().then(() => this.isEstimatifsLoading = false);
            }
        }
    }

    public async deleteEstimatifClient(record: IEstimatifClientRowDefinition): Promise<void> {
        const isSuccess = await estimatifsClientHttp.deleteEstimatifClient(record.estimatifClientId);

        if (isSuccess) {
            this.chargerEstimatifsClient()
                .then(() => this.isEstimatifsLoading = false);
        }
    }

    public async downloadProjetArtisan(record: IEstimatifClientRowDefinition): Promise<void> {
        record.exportState.isDownloadingArtisan = true;
        await this.downloadProjet(record.estimatifClientId, models.EditionMode.Artisan);
        record.exportState.isDownloadingArtisan = false;
    }

    public async downloadProjetArtisanAvecMetre(record: IEstimatifClientRowDefinition): Promise<void> {
        record.exportState.isDownloadingArtisanAvecMetre = true;
        await this.downloadProjet(record.estimatifClientId, models.EditionMode.ArtisanAvecMetre);
        record.exportState.isDownloadingArtisanAvecMetre = false;
    }

    public async downloadProjetArtisanSansMetre(record: IEstimatifClientRowDefinition): Promise<void> {
        record.exportState.isDownloadingArtisanSansMetre = true;
        await this.downloadProjet(record.estimatifClientId, models.EditionMode.ArtisanSansMetre);
        record.exportState.isDownloadingArtisanSansMetre = false;
    }

    public async downloadProjetClient(record: IEstimatifClientRowDefinition): Promise<void> {
        record.exportState.isDownloadingClient = true;
        await this.downloadProjet(record.estimatifClientId, models.EditionMode.Client);
        record.exportState.isDownloadingClient = false;
    }

    public async downloadProjetClientAvecMetre(record: IEstimatifClientRowDefinition): Promise<void> {
        record.exportState.isDownloadingClientAvecMetre = true;
        await this.downloadProjet(record.estimatifClientId, models.EditionMode.ClientAvecMetre);
        record.exportState.isDownloadingClientAvecMetre = false;
    }

    public async downloadProjetRealConcept(record: IEstimatifClientRowDefinition): Promise<void> {
        record.exportState.isDownloadingRealConcept = true;
        await this.downloadProjet(record.estimatifClientId, models.EditionMode.RealConcept);
        record.exportState.isDownloadingRealConcept = false;
    }

    public async downloadProjetRealConceptAlerteSansMarge(record: IEstimatifClientRowDefinition): Promise<void> {
        record.exportState.isDownloadingRealConceptAlerteSansMarge = true;
        await this.downloadProjet(record.estimatifClientId, models.EditionMode.RealConceptAlerteSansMarge);
        record.exportState.isDownloadingRealConceptAlerteSansMarge = false;
    }

    public async duplicateEstimatifClient(record: IEstimatifClientRowDefinition): Promise<void> {
        const isSuccess = await estimatifsClientHttp.duplicate(record.estimatifClientId);

        if (isSuccess) {
            this.chargerEstimatifsClient()
                .then(() => this.isEstimatifsLoading = false);
        }
    }

    public navigateToEstimatif(record: IEstimatifClientRowDefinition): void {
        this.$router.push({ name: "estimatif", params: { id: record.estimatifClientId }});
    }

    public obtenirDateAffichage(record: IEstimatifClientRowDefinition): string {
        return record.dateCreation.format("DD/MM/YYYY");
    }

    public showParametrageEstimatif(record: IEstimatifClientRowDefinition): void {
        this.parametrageEstimatifIsVisible = true;
        this.parametrageEstimatifDrawerTitle = "Paramétrage de l'estimatif";
        this.parametrageEstimatifDrawerTitle += ` ${record.numero}-${record.version}`;
        this.parametrageEstimatifDrawerTitle += " du client";
        this.parametrageEstimatifDrawerTitle += ` ${this.civiliteDisplay} ${this.nomSocieteDisplay}`;
        this.estimatifClient = { ...(record as models.IEstimatifClient) };
    }

    public updateEstimatifClient(estimatifClient: models.IEstimatifClient): void {
        const estimatifClt = this.estimatifsClient.find(ec => ec.estimatifClientId === estimatifClient.estimatifClientId);

        if (estimatifClt) {
            estimatifClt.projet = estimatifClient.projet;
            estimatifClt.honoraire = estimatifClient.honoraire;
            estimatifClt.adresseConstruction = estimatifClient.adresseConstruction;
            estimatifClt.adresseComplementConstruction = estimatifClient.adresseComplementConstruction;
            estimatifClt.codePostalConstruction = estimatifClient.codePostalConstruction;
            estimatifClt.villeConstruction = estimatifClient.villeConstruction;
            estimatifClt.commentaire = estimatifClient.commentaire;
        }
    }

    private async downloadProjet(estimatifClientId: number, edition: models.EditionMode): Promise<void> {
        const response = await exportsHttp.downloadProjet(estimatifClientId, edition);

        if (response?.status === 200) {
            const contentDispositionHeader: string = response.headers["content-disposition"];
            const fileName = parse(contentDispositionHeader);
            fileDownload(response.data, fileName.parameters.filename);
        }
    }

    private getCiviliteRef(civiliteId: number): models.ICivilite {
        return this.$store.getters["references/civilitesRecords"][civiliteId] ?? {
            civiliteId: 0,
            code: "-",
            libelle: "-",
        };
    }
}

interface IEstimatifClientRowDefinition extends models.IEstimatifClient {
    key: string;
    exportState: IExportState;
    numeroVersion: string;
    hasAlerteSansMarge: boolean;
}

interface IColumnDefinition {
    key: string;
    dataIndex: string;
    title: string;
    width?: string | number;
}

interface IExportState {
    isDownloadingArtisan: boolean;
    isDownloadingArtisanAvecMetre: boolean;
    isDownloadingArtisanSansMetre: boolean;
    isDownloadingClient: boolean;
    isDownloadingClientAvecMetre: boolean;
    isDownloadingRealConcept: boolean;
    isDownloadingRealConceptAlerteSansMarge: boolean;
}
