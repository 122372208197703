import { Options, Vue } from "vue-class-component";

import ModifyCategorieModalProps from "./ModifyCategorieModalProps";

import * as models from "@/models";

@Options({
    name: "modify-categorie-modal",
    emits: ["update:modifyCategorieParameters", "updateCategorie"],
})
export default class ModifyCategorieModal extends Vue.with(ModifyCategorieModalProps) {
    public isUpdatingModifyCategorie = false;

    public selectionChange = false;

    public get categoriesHierarchie(): Record<number, models.ICategorieHierarchie> {
        return this.$store.state["references"]?.categoriesHierarchie as Record<number, models.ICategorieHierarchie>
            ?? {};
    }

    public get firstCategories(): models.ICategorie[] {
        return Object.values(this.categoriesHierarchie)
            .map(ch => ch.categorie)
            .sort((ch1, ch2) => ch1.reference - ch2.reference);
    }

    public get secondCategories(): models.ICategorie[] {
        return this.modifyCategorieParameters.categories.firstCategorieId
            ? Object.values(this.categoriesHierarchie[this.modifyCategorieParameters.categories.firstCategorieId].categoriesHierarchie ?? {})
                .map(ch => ch.categorie)
                .sort((ch1, ch2) => ch1.reference - ch2.reference)
            : null;
    }

    public get thirdCategories(): models.ICategorie[] {
        return this.modifyCategorieParameters.categories.secondCategorieId
            ? Object.values(
                this.categoriesHierarchie[this.modifyCategorieParameters.categories.firstCategorieId]
                .categoriesHierarchie[this.modifyCategorieParameters.categories.secondCategorieId]
                .categoriesHierarchie ?? {})
                .map(ch => ch.categorie)
                .sort((ch1, ch2) => ch1.reference - ch2.reference)
            : [];
    }

    public get canUpdateCategories(): boolean {
        return this.selectionChange
            && ((this.modifyCategorieParameters.categories.secondCategorieId > 0 && this.thirdCategories.length === 0)
                || this.modifyCategorieParameters.categories.thirdCategorieId > 0);
    }

    public closeModifyCategorieModal(): void {
        this.modifyCategorieParameters.visible = false;
        this.modifyCategorieParameters.estimatifId = null;
        this.modifyCategorieParameters.categories = null;

        this.emitModel();
    }

    public onFirstCategorieSelectionChange(): void {
        this.modifyCategorieParameters.categories.secondCategorieId = null;
        this.modifyCategorieParameters.categories.thirdCategorieId = null;
        this.selectionChange = true;
        this.emitModel();
    }

    public onSecondCategorieSelectionChange(): void {
        this.modifyCategorieParameters.categories.thirdCategorieId = null;
        this.selectionChange = true;
        this.emitModel();
    }

    public onThirdCategorieSelectionChange(): void {
        this.selectionChange = true;
        this.emitModel();
    }

    public async onUpdateCategories(): Promise<void> {
        let categorieId: number = null;
        this.isUpdatingModifyCategorie = true;

        if (this.modifyCategorieParameters.categories.thirdCategorieId != null) {
            categorieId = this.modifyCategorieParameters.categories.thirdCategorieId;
        }
        else {
            categorieId = this.modifyCategorieParameters.categories.secondCategorieId;
        }

        this.$emit("updateCategorie", categorieId);

        this.modifyCategorieParameters.visible = false;
        this.emitModel();

        this.isUpdatingModifyCategorie = false;
    }

    private emitModel(): void {
        this.$emit("update:modifyCategorieParameters", this.modifyCategorieParameters);
    }
}
