import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
    name: "number-format",
})
export default class NumberFormat extends Vue {
    @Prop({ required: true })
    public value: number;

    @Prop
    public options: Intl.NumberFormatOptions;

    public get isPercent(): boolean {
        return this.numberFormatOptions.style === "percent";
    }

    public get numberFormatOptions(): Intl.NumberFormatOptions {
        return this.options ?? {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        };
    }

    public get rightValue(): number {
        return this.value ? (this.isPercent ? this.value / 100 : this.value) : null;
    }

    public get formattedValue(): string {
        const val = this.rightValue;
        return val
            ? new Intl.NumberFormat(
                "fr-FR",
                this.numberFormatOptions).format(val)
            : "";
    }
}
