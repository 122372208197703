import { msalInstance, realConceptScopes } from "@/services";
import { settings } from "@/settings";
import { notification } from "ant-design-vue";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";

export default class BaseHttp {
    protected apiClient: AxiosInstance;

    constructor(uriPath: string) {
        this.apiClient = axios.create({
            baseURL: `${settings.webApi.baseUrl}${uriPath}`,
            responseType: "json",
        });

        this.apiClient.interceptors.request.use(this.requestInterceptor);
        this.apiClient.interceptors.response.use(null, this.errorResponseInterceptor);
    }

    protected async create<T>(service: string, object: T, errorMessage?: string): Promise<{ id: number; state: boolean }> {
        try {
            const response = await this.apiClient.post(service, object);

            if (response.status === 200) {
                return { id: response.data as number, state: true };
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Erreur",
                description: errorMessage ?? "Une erreur s'est produite dans la création des données",
            });

            return { id: 0, state: false };
        }
    }

    protected async delete(id: number, errorMessage?: string): Promise<boolean> {
        try {
            const response = await this.apiClient.delete(`${id}`);

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Erreur",
                description: errorMessage ?? "Une erreur s'est produite lors de la suppression des données",
            });

            return false;
        }
    }

    protected async get<T>(service: string, errorMessage?: string): Promise<T | null> {
        try {
            const response = await this.apiClient.get<T>(service);

            if (response.status === 200) {
                return response.data;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Erreur",
                description: errorMessage ?? "Une erreur s'est produite lors de la récupération des données",
            });
        }

        return null;
    }

    protected async update<T>(service: string, id: number, object: T, errorMessage?: string): Promise<boolean> {
        try {
            const response = await this.apiClient.put(`${service}/${id}`, object);

            if (response.status === 200) {
                return true;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            notification.error({
                message: "Erreur",
                description: errorMessage ?? "Une erreur s'est produite lors de la mise à jour des données",
            });

            return false;
        }
    }

    protected isAxiosError(error: Error): error is AxiosError {
        return (error as AxiosError).isAxiosError;
    }

    private async requestInterceptor(request: AxiosRequestConfig) {
        const accessToken = await msalInstance.getAccessToken(realConceptScopes);

        // Add Authorization token
        if (accessToken != null) {
            request.headers = { "Authorization": `Bearer ${accessToken}` };
        }

        return Promise.resolve(request);
    }

    private async errorResponseInterceptor(error: AxiosError) {
        // If the user is unauthenticated, retry login
        if (error.response.status === 401) {
            await msalInstance.getAccessToken(realConceptScopes);
        }

        return Promise.reject(error);
    }
}
