import * as models from "@/models/api";

interface IReferencesState {
    categoriesHierarchie: Record<number, models.ICategorieHierarchie>;
    categoriesHierarchieLoaded: boolean;
    civilites: models.ICivilite[];
    civilitesLoaded: boolean;
    projets: models.IProjet[];
    projetsLoaded: boolean;
    tvas: models.ITva[];
    tvasLoaded: boolean;
    unites: models.IUnite[];
    unitesLoaded: boolean;
}

const initialState: IReferencesState = {
    categoriesHierarchie: {},
    categoriesHierarchieLoaded: false,
    civilites: [],
    civilitesLoaded: false,
    projets: [],
    projetsLoaded: false,
    tvas: [],
    tvasLoaded: false,
    unites: [],
    unitesLoaded: false,
};

export { IReferencesState, initialState };
