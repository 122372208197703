import * as models from "@/models/api";
import BaseHttp from "./BaseHttp";

export default class ReferencesHttp extends BaseHttp {
    public constructor() {
        super("References");
    }

    public async obtenirCategoriesHierarchie(): Promise<Record<number, models.ICategorieHierarchie>> {
        const data = await this.get<Record<number, models.ICategorieHierarchie>>(
            "/CategoriesHierarchie",
            "Une erreur s'est produite lors de la récupération de la hiérarchie des catégories.");
        return data ?? {};
    }

    public async obtenirCivilites(): Promise<models.ICivilite[]> {
        const data = await this.get<models.ICivilite[]>("/Civilites", "Une erreur s'est produite lors de la récupération des civilités.");
        return data ?? [];
    }

    public async obtenirProjets(): Promise<models.IProjet[]> {
        const data = await this.get<models.IProjet[]>("/Projets", "Une erreur s'est produite lors de la récupération des projets.");
        return data ?? [];
    }

    public async obtenirTvas(): Promise<models.ITva[]> {
        const data = await this.get<models.ITva[]>("/Tvas", "Une erreur s'est produite lors de la récupération des TVA.");
        return data ?? [];
    }

    public async obtenirUnites(): Promise<models.IUnite[]> {
        const data = await this.get<models.IUnite[]>("/Unites", "Une erreur s'est produite lors de la récupération des unités.");
        return data ?? [];
    }
}

const referencesHttp = new ReferencesHttp();
export { ReferencesHttp, referencesHttp };
