import { GetterTree } from "vuex";
import { IAccountState } from "./state";

const getters: GetterTree<IAccountState, any> = {
    isAuthenticated: (state): boolean => !(!state.msalAccount),
    userEmail: (state): string => (state.msalAccount?.idTokenClaims as any).email,
    userAccountId: (state): string => (state.msalAccount?.localAccountId as any),
};

export { getters };
