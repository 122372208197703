import { Module } from "vuex";
import { IReferencesState, initialState } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const references: Module<IReferencesState, any> = ({
    namespaced: true,
    state: () => initialState,
    getters,
    mutations,
    actions,
});

export { references };
