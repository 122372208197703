import { prop } from "vue-class-component";

import * as models from "@/models";

export default class CreateOrUpdateEstimatifLineProps {
    estimatifLine: models.IEstimatifBase = prop({
        required: true,
    });

    categoriesSelected: models.ICategoriesSelection = prop({
        required: true,
    });

    tvas: models.ITva[] = prop({
        required: true,
    });

    unites: models.IUnite[] = prop({
        required: true,
    });
}
