export const settings = {
    environment: process.env.VUE_APP_ENV as string,
    azureAd: {
        clientId: process.env.VUE_APP_AZURE_AD_CLIENTID as string,
        tenantId: process.env.VUE_APP_AZURE_AD_TENANTID as string,
        instance: process.env.VUE_APP_AZURE_AD_INSTANCE as string,
        callbackPath: process.env.VUE_APP_AZURE_AD_CALLBACKPATH as string,
    },
    webApi: {
        baseUrl: process.env.VUE_APP_WEB_API_BASE_URL as string,
    },
};
