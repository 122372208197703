import * as msal from "@azure/msal-browser";
import { MutationTree } from "vuex";
import { IAccountState } from "./state";
import * as mutationTypes from "./mutations-types";

export const mutations: MutationTree<IAccountState> = {
    [mutationTypes.LOAD_ACCOUNT]: (state, payload: msal.AccountInfo) => {
        state.msalAccount = payload;
    },

    [mutationTypes.LOGOUT]: (state) => {
        state.msalAccount = null;
    },
};
