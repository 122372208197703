import * as models from "@/models/api";
import { GetterTree } from "vuex";
import { IReferencesState } from "./state";

export const getters: GetterTree<IReferencesState, any> = {
    civilites: (state): models.ICivilite[] => state.civilites,

    civilitesRecords: (_, currentGetters): Record<number, models.ICivilite> =>
        currentGetters.civilites.reduce((hash, civilite) => ({ ...hash, [civilite.civiliteId]: civilite }), {}),

    tvas: (state): models.ITva[] => state.tvas,

    tvasRecords: (_, currentGetters): Record<number, models.ITva> =>
        currentGetters.tvas.reduce((hash, tva) => ({ ...hash, [tva.tvaId]: tva }), {}),

    unites: (state): models.IUnite[] => state.unites,

    unitesRecords: (_, currentGetters): Record<number, models.IUnite> =>
        currentGetters.tvas.reduce((hash, unite) => ({ ...hash, [unite.uniteId]: unite }), {}),

    /** Check if all data is loaded */
    referencesLoaded: (state): boolean =>
        state.categoriesHierarchieLoaded
        && state.civilitesLoaded
        && state.projetsLoaded
        && state.tvasLoaded
        && state.unitesLoaded,
};
