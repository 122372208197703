import { AxiosResponse } from "axios";
import { notification } from "ant-design-vue";

import BaseHttp from "./BaseHttp";
import { EditionMode } from "@/models";

class ExportsHttp extends BaseHttp {

    public constructor() {
        super("Export");
    }

    public async downloadProjet(estimatifClientId: number, edition: EditionMode): Promise<AxiosResponse> {
        return await this.downloadFile(`projet/${estimatifClientId}/${edition}`);
    }

    private async downloadFile(url: string): Promise<AxiosResponse> {
        try {
            return await this.apiClient.get<Blob>(url, {
                responseType: "blob",
                timeout: 60_000,
            });
        }
        catch (error: any) {
            if (this.isAxiosError(error)) {
                notification.error({
                    message: "Erreur",
                    description: "Un erreur s'est produite lors du téléchargement du projet",
                });

                return error.response;
            }

            return null;
        }
    }
}

const exportsHttp = new ExportsHttp();
export { ExportsHttp, exportsHttp };
