const FETCH_CATEGORIES_HIERARCHIE = "FETCH_CATEGORIES_HIERARCHIE";
const SET_CATEGORIES_HIERARCHIE = "SET_CATEGORIES_HIERARCHIE";
const FETCH_CIVILITES = "FETCH_CIVILITES";
const SET_CIVILITES = "SET_CIVILITES";
const FETCH_PROJETS = "FETCH_PROJETS";
const SET_PROJETS = "SET_PROJETS";
const FETCH_TVAS = "FETCH_TVAS";
const SET_TVAS = "SET_TVAS";
const FETCH_UNITES = "FETCH_UNITES";
const SET_UNITES = "SET_UNITES";

export {
    FETCH_CATEGORIES_HIERARCHIE,
    SET_CATEGORIES_HIERARCHIE,
    FETCH_CIVILITES,
    SET_CIVILITES,
    FETCH_PROJETS,
    SET_PROJETS,
    FETCH_TVAS,
    SET_TVAS,
    FETCH_UNITES,
    SET_UNITES,
};
